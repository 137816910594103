import type { AuthObject } from "@/app";
import { writable } from "svelte/store";

const authStore = writable<AuthObject>({
  isLoggedIn: false,
  firebaseControlled: false,
});

export default {
  subscribe: authStore.subscribe,
  set: authStore.set,
};
