/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FirestoreDataConverter } from "firebase/firestore";
import { DisallowWord } from "../../functions/src/models/disallowWord";

export const disallowWordConverter: FirestoreDataConverter<DisallowWord> = {
  toFirestore: (item) => {
    const copy = { ...item };
    if (item.modifiedBy) {
      (copy as any).modified_by = item.modifiedBy;
      delete copy.modifiedBy;
    }
    if (item.addedBy) {
      (copy as any).added_by = item.addedBy;
      delete copy.addedBy;
    }
    return copy;
  },
  fromFirestore: (snapshot, options) => {
    // eslint-disable-next-line camelcase
    const { added, added_by, modified, modified_by, source } = snapshot.data(options);
    return new DisallowWord(added, added_by, modified, modified_by, source);
  },
};
