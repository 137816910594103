import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebase";
import { authStore } from "@/stores";

const provider = new GoogleAuthProvider();

// auth.languageCode = 'nl';

export function signInWithGoogle(): void {
  signInWithPopup(auth, provider)
    .then(GoogleAuthProvider.credentialFromResult)
    .catch(GoogleAuthProvider.credentialFromError);
}

export function logOut(): void {
  signOut(auth)
    .then(() => {
      console.info("User has signed out");
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function authListener() {
  onAuthStateChanged(auth, async (user) => {
    authStore.set({
      isLoggedIn: user !== null,
      user,
      firebaseControlled: true,
    });
  });
}

export async function isUser(email?: string | null): Promise<boolean> {
  if (!email) return false;

  const docRef = await getDoc(doc(db, "users", email));

  return docRef.exists();
}
