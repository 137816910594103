import type { FirebaseOptions } from "firebase/app";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { dev } from "$app/environment";
import { getFirestore, collection, connectFirestoreEmulator } from "firebase/firestore";
import { disallowWordConverter } from "./converters";
import type { Language } from "@/stores/i18n";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBZb35YSHUU_nKI4vErl5LxkMb3U95pVuk",
  authDomain: "q42puzzles-wordlists.firebaseapp.com",
  projectId: "q42puzzles-wordlists",
  storageBucket: "q42puzzles-wordlists.appspot.com",
  messagingSenderId: "266316428811",
  appId: "1:266316428811:web:f00eb4e17abd2a4024390e",
  measurementId: "G-F21S6079M2",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const fbFunctions = getFunctions(app, "europe-west1");

export const digitalUrl = dev
  ? "https://dashboard.42puzzles.com"
  : "https://dashboard-test.42puzzles.com";
export const printUrl = dev ? "https://test.dpgpuzzels.nl" : "https://dpgpuzzels.nl";
export const inboxUrl = dev ? "https://inbox-test.42puzzles.com" : "https://inbox.42puzzles.com";

if (dev) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(fbFunctions, "localhost", 5001);
}

export const offensiveRef = (lang: Language) =>
  collection(db, `languages/${lang}/offensive`).withConverter(disallowWordConverter);
export const unsuitableRef = (lang: Language) =>
  collection(db, `languages/${lang}/unsuitable`).withConverter(disallowWordConverter);
export const allowedRef = (lang: Language) =>
  collection(db, `languages/${lang}/allowed`).withConverter(disallowWordConverter);
